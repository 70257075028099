@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "PT Serif", sans-serif;
  background: white;
}

@font-face {
  font-family: "Futura Extra Bold";
  src: local("Futura Extra Bold"),
    url(/static/media/Futura_Extra_Bold.db7c22c7.otf) format("truetype");
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Login_Wrapper__3YnoL {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.Login_LoginContainer__14p0B {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.Login_Login__cTCkc {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.Login_ImgWrapper__6FIce {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.Login_ImgWrapper__6FIce img {
  width: 100%;
}

.Login_LoginTitle__1tMva {
  font-size: 2em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 40px;
}
.Login_LoginTitle__1tMva span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ====FORM STYLING START====== */
.Login_InputGroup__3lXB4 {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Login_Input__2exDO {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Login_Input__2exDO:focus {
  outline: none;
}
.Login_Phone__2NoT2 {
  padding-left: 45px;
}
.Login_InputGroup__3lXB4 i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
.Login_MUIInputGroup__DhuGk {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.Login_InputError__1YLFE {
  font-size: 0.85rem;
  color: #e0004d;
  margin-top: 0.5em;
}
/* LABEL ======= */
.Login_InputLabel__1Avj- {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Login_Input__2exDO:focus ~ .Login_InputLabel__1Avj-,
.Login_Input__2exDO:valid ~ .Login_InputLabel__1Avj- {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Login_Bar__LhVLa {
  position: relative;
  display: block;
  width: 100%;
}
.Login_Bar__LhVLa:before,
.Login_Bar__LhVLa:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Login_Bar__LhVLa:before {
  left: 50%;
}
.Login_Bar__LhVLa:after {
  right: 50%;
}
/* active state */
.Login_Input__2exDO:focus ~ .Login_Bar__LhVLa:before,
.Login_Input__2exDO:focus ~ .Login_Bar__LhVLa:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Login_Highlight__2AuQQ {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Login_Input__2exDO:focus ~ .Login_Highlight__2AuQQ {
  animation: Login_inputHighlighter__3HcpT 0.3s ease;
  -webkit-animation: Login_inputHighlighter__3HcpT 0.3s ease;
  -moz-animation: Login_inputHighlighter__3HcpT 0.3s ease;
}
/* Animations */
@keyframes Login_inputHighlighter__3HcpT {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Login_inputHighlighter__3HcpT {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Login_Indicatif__2Dm1i {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Login_Input__2exDO:focus ~ .Login_Indicatif__2Dm1i,
.Login_Input__2exDO:valid ~ .Login_Indicatif__2Dm1i {
  opacity: 1;
}

/* ========= CGU CHECKBOX =========== */

.Login_CGUGroup__2Awhf input[type="checkbox"] {
  display: none;
}
.Login_CGUGroup__2Awhf input[type="checkbox"] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

.Login_CGUGroup__2Awhf input[type="checkbox"]:checked + label:before {
  content: "\f046";
}
.Login_CGUGroup__2Awhf input[type="checkbox"]:checked + label:before {
  letter-spacing: 8px;
}

.Login_CGUGroup__2Awhf input[type="checkbox"] + label:before {
  content: "\f096";
}
.Login_CGUGroup__2Awhf input[type="checkbox"] + label:before {
  letter-spacing: 10px;
}

/* CGU Link ====== */
.Login_CGUGroup__2Awhf label {
  color: #828489;
  font-size: 0.9em;
}
.Login_CGUGroup__2Awhf span {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ======SUBMIT BUTTON ====== */
.Login_SubmitButtonContainer__KXFhD {
  margin-top: 40px;
}

/* Anon link */
.Login_AnonLogin__2lJmH {
  margin-top: 1em;
  text-align: center;
}

.RefInfo_RefInfoTitle__230fg {
  display: flex;
}
.RefInfo_RefInfoTitle__230fg h3 {
  margin-left: 8px;
}

.RefInfo_RefWrapper__26tPi img {
  width: 100%;
  margin-top: 15px;
}

.CGU_CGUTitle__2UX4Q {
  text-align: center;
}

.CGU_CGUWrapper__10GfX {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.CGU_Article__3ng3g {
  margin-bottom: 1em;
}

.CGU_ArticleTitle__2wkt4 {
  margin-bottom: 0.5em;
}
.CGU_ArticleSubtitle__2MlsF {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__1YOUH {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__1YOUH p {
  margin-bottom: 0.3em;
}

.Modal_Modal__2S63y {
  position: fixed;
  z-index: 500;
  background: white;
  border-radius: 9px;
  width: 90%;
  padding: 16px;
  left: 5%;
  top: 30%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal_Modal__2S63y {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.Backdrop_Backdrop__3Ep5z {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  /* background-color: rgba(229, 229, 229, 0.8); */
  background-color: rgba(0, 0, 0, 0.5);
}

.SubmitButton_SubmitButton__3SdRy {
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 1em 1em;
  min-width: 10em;
  border: 0;
  outline: 0;
  font-size: 0.9rem;
  text-align: center;
  /* Primaire Rouge 1 */
  background: #e0004d;
  /* 6dp elevation */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 6px 10px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.SubmitButton_SubmitButton__3SdRy:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.SubmitButton_SubmitButton__3SdRy:disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

/* Invalid state */
.SubmitButton_Invalid__2aHA4 i {
  -webkit-transform: translate(-30vw, 11vh);
          transform: translate(-30vw, 11vh);
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
}
.SubmitButton_Invalid__2aHA4 span {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* transition: all linear 0.2s; */
}

/* Valid state */
.SubmitButton_Valid__1pCgg i {
  color: #ffc300;
  font-size: 1.4em;
  margin-left: 0.4em;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 1;
}

/* Loading state */
.SubmitButton_Clicked__5L6VF i {
  -webkit-transform: translate(100vw, -90vh);
          transform: translate(100vw, -90vh);
  -webkit-transition: all linear 1.3s;
  transition: all linear 1.3s;
}

.CircularLoading_CircularLoading__3C4lO {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Home_SectionTitle__2bPk6 {
  text-align: center;
  font-family: "Merriweather", serif;
}
.Home_SectionTitle__2bPk6 .Home_Emoji__lUgPl {
  font-size: 2.8em;
}
.Home_SectionTitle__2bPk6 h3 {
  font-size: 1.5em;
}
.Home_SectionSubtitle__Wx9Ng {
  font-size: 1rem;
  margin: 0.8em 2em;
  margin-left: 4em;
  color: #898786;
}

.Home_EmojiText__22MXm {
  display: flex;
  margin: 0 2em;
  color: #5b5855;
}
.Home_EmojiText__22MXm .Home_Emoji__lUgPl {
  font-size: 1.2em;
}

.Home_EmojiText__22MXm p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.Home_ParticiperButton__R6WHV {
  text-align: center;
  margin: 1.5em 0;
}

.Home_HighlightNote__2qpvA {
  margin: 2em 4em;
}
.Home_HighlightNote__2qpvA span {
  display: inline-block;
  padding: 0.25em 2em;
  background-color: #e0004d;
  margin-bottom: 1em;
  border-radius: 3px;
}
.Home_HighlightNote__2qpvA p {
  text-align: center;
  color: #e0004d;
  font-weight: bold;
  font-size: 1.1rem;
}

.Home_Illustration__DuezB {
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2em auto;
}

/* Caroussel */
@media screen and (max-width: 767px) {
  .Home_CarouselSection__2HAsX {
    margin: 30px 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .Home_CarouselSection__2HAsX::-webkit-scrollbar {
    display: none; /* Safari & Chrome*/
  }

  .Home_CarouselItem__21mgR {
    flex: 0 0 auto;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 30px;
    width: 275px;
    height: 275px;
    box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .Home_CarouselItem__21mgR:first-child {
    margin-left: 40px;
  }
  .Home_CourselItem__1Z0-d:last-child {
    margin-right: 20px;
  }
}

.Home_GrandesEtapes__1o-rm {
  display: flex;
  justify-content: center;
}
.Home_GrandesEtapes__1o-rm img {
  width: 80%;
  max-width: 33em;
  margin-bottom: 2em;
}
.Home_HeroImage__4mYtz {
  width: 100%;
  margin-top: 3.5em;
  margin-bottom: 1.5em;
  z-index: -1;
}
.Home_HeroImage__4mYtz img {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
  .Home_HeroImage__4mYtz {
    margin-top: 0em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Home_HomeCardBackground__18bPv {
    padding: 0 7em;
    background-color: #f3f2f0;
  }
  .Home_HomeCardContent__2AYBY {
    background-color: white;
    padding-bottom: 2em;
  }
  .Home_HeroImage__4mYtz {
    width: 100%;
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 3em;
  }
  /* .HeroImage img {
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.14);
  } */
  .Home_Section__39zNj {
    margin: 0 2em;
  }
  .Home_SectionTitle__2bPk6 {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    text-align: left;
  }
  .Home_SectionTitle__2bPk6 .Home_Emoji__lUgPl {
    font-size: 3rem;
  }
  .Home_SectionSubtitle__Wx9Ng {
    font-size: 1.2rem;
    margin: 0.8em 0;
    margin-left: 3.5em;
  }
  .Home_EmojiText__22MXm p {
    font-size: 1rem;
  }

  .Home_HighlightNote__2qpvA p {
    font-size: 1.3rem;
  }
  .Home_ContactForm__3ZXoc {
    margin: 0 auto;
    max-width: 40em;
  }

  /* Caroussel */
  .Home_CarouselSection__2HAsX {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2em 0;
  }

  .Home_CarouselItem__21mgR {
    margin: 0.5em 1em;
    width: 15em;
    height: 15em;
    box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Home_HomeCardBackground__18bPv {
    padding: 0 13em;
  }

  .Home_HeroImage__4mYtz {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 4em;
    margin-top: 3em;
  }
  .Home_Section__39zNj {
    margin: 0 5em;
  }
  .Home_Illustration__DuezB {
    width: 25em;
    height: 25em;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
  .Home_HomeCardBackground__18bPv {
    padding: 0 16em;
  }
}

.Blog_Blog__3EZCe {
  margin: 50px 0;
  box-sizing: border-box;
}

.Blog_BlogTitle__2zOnO {
  display: flex;
  margin: 0 20px;
}
.Blog_BlogTitle__2zOnO h2 {
  margin-left: 5px;
}

/* Small tablets */
@media (min-width: 620px) {
  .Blog_BlogTitle__2zOnO {
    margin: 0 50px;
  }
}

.BlogCard_BlogCard__1zm0D {
  margin: 30px auto;
  max-width: 23rem;
  min-height: 32rem;
  width: 75%;
  background: #f3f2f0;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 8px;
  overflow: hidden;
}

.BlogCard_BlogCardImage__23X_N {
  height: 22em;
  width: 100%;
  z-index: -1;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  background-repeat: no-repeat;
  background-size: cover;
}

.BlogCard_BlogCardDate__1zu7R {
  text-align: end;
  margin: 0.7rem 1.5rem;
  font-size: 0.9em;
  color: #9dabb6;
}

.BlogCard_BlogCardContent__fGn66 {
  margin: 0.7rem 1.5rem;
}
.BlogCard_BlogCardContent__fGn66 p {
  margin: 0.3rem 0;
}

.BlogCard_BlogCardButton__3TeXY {
  text-align: end;
  margin: 0.7rem 1.5rem;
  margin-top: 2rem;
}

/* Small tablets */
@media (min-width: 620px) {
  .BlogCard_BlogCard__1zm0D {
    margin: 50px auto;
  }
}

.LongButton_LongButton__2WqcH {
  font-family: inherit;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 15px;
  outline: none;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 4px;
  cursor: pointer;
}
.LongButton_LongButton__2WqcH:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.ContactForm_ContactForm__2-opD {
  margin: 1.5em 0.3em;
}
.ContactForm_ContactFormTitle__1ErDD {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}
.ContactForm_Emoji__2X2IL {
  font-size: 1.5rem;
}
.ContactForm_ContactFormFields__15I_S {
  margin: 2em;
  display: flex;
  flex-direction: column;
}
.ContactForm_Button__2SX_y {
  text-align: end;
}
.ContactForm_Success__ply4m {
  margin-left: 0.2em;
  margin-bottom: 1em;
  color: green;
  font-size: 0.8rem;
}
.ContactForm_Error__26hK9 {
  margin-bottom: 1em;
  margin-left: 0.2em;
  color: red;
  font-size: 0.8rem;
}

.Footer_Footer__3QwMS {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e0004d;
}
.Footer_LogoContainer__3mmbm {
  width: 13em;
  margin: 1em 0;
}
.Footer_LogoContainer__3mmbm img {
  width: 100%;
}
.Footer_LegalLinks__3uvPD {
  display: flex;
  margin-top: 1em;
}
.Footer_LegalLinks__3uvPD p {
  cursor: pointer;
  margin: 0 1em;
  font-size: 0.8rem;
  font-weight: bold;
  color: white;
}
.Footer_Copyrights__2_IG6 {
  margin: 1em 0;
  color: white;
  text-align: center;
  font-size: 0.8rem;
}
.Footer_Copyrights__2_IG6 span {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  font-family: "Arial Black", Gadget, sans-serif;
}

.PDC_CGUTitle__1WU0d {
  text-align: center;
}

.PDC_CGUWrapper__O8lxv {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.PDC_Article__iqDG2 {
  margin-bottom: 1em;
}

.PDC_ArticleTitle__108ed {
  margin-bottom: 0.5em;
}
.PDC_ArticleSubtitle__3zKs- {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__XjNUj {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__XjNUj p {
  margin-bottom: 0.3em;
}

.Separator_Separator__2coPR {
  margin: 0 auto;
  height: 4px;
  width: 90%;
  max-width: 60em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset 1px 3px 5px #a6abbd, inset 7px 4px 14px #fafbff;
  border-radius: 4px;
}

/* .Content {
  margin-top: 56px;
} */

/* Small tablets */

/* Large tablets & laptops */
@media (min-width: 960px) {
  .Layout_Content__10gh1 {
    margin-top: 0px;
  }
}

.Toolbar_Toolbar__1W2Pv {
  height: 57px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #ebecf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
}

/* .Logo {
  height: 60%;
} */
.Toolbar_Logo__2ZQA- h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #5b5855;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 619px) {
  .Toolbar_DesktopOnly__35Eh7 {
    display: none;
  }
}

/* Small tablets */
@media (min-width: 620px) {
  .Toolbar_Toolbar__1W2Pv {
    /* box-shadow: none; */
    height: 70px;
    padding: 0 40px;
    /* position: relative; */
    background-color: white;
  }
  .Toolbar_Logo__2ZQA- h3 {
    font-size: 1.8rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 960px) {
  .Toolbar_Toolbar__1W2Pv {
    /* height: 100px; */
    padding: 10px 80px;
  }
  .Toolbar_Logo__2ZQA- h3 {
    font-size: 2rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Toolbar__1W2Pv {
    padding: 10px 100px;
  }
}

.SideDrawer_SideDrawer__xpenQ {
  position: fixed;
  width: 280px;
  max-width: 60%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: #ebecf0;
  padding: 32px 16px;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 620px) {
  .SideDrawer_SideDrawer__xpenQ {
    display: none;
  }
}

.SideDrawer_Open__3FGT4 {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.SideDrawer_Close__20PHK {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.SideDrawer_Logo__1L6XS {
  height: 11%;
  margin-bottom: 32px;
}

.NavigationItems_NavigationItems__1-9h4 {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}

/* Small tablets */
@media screen and (min-width: 620px) {
  .NavigationItems_NavigationItems__1-9h4 {
    flex-flow: row;
    width: 50vw;
  }

  .NavigationItems_Participer__3JsF3 {
    margin-left: 25px;
  }
}

/* Large tablets & laptops */
@media (min-width: 960px) {
  .NavigationItems_NavigationItems__1-9h4 {
    width: 45vw;
  }
}

.NavigationItem_NavigationItem__1c4md {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem_NavigationItem__1c4md a {
  color: #2a2f39;
  padding: 10px 20px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 7px;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.NavigationItem_NavigationItem__1c4md a:hover,
.NavigationItem_NavigationItem__1c4md a:active,
.NavigationItem_NavigationItem__1c4md a.NavigationItem_active__QFPCu {
  color: #e6404d;
  font-weight: bold;
  box-shadow: inset 5px 5px 10px #a6abbd, inset -5px -5px 10px #fafbff;
}

/* Small tablets */
@media (min-width: 620px) {
  .NavigationItem_NavigationItem__1c4md {
    margin: 10px 10px;
    padding: 10 30px;
  }
  .NavigationItem_NavigationItem__1c4md a {
    border: none;
    box-shadow: none;
    padding: 10px 0;
  }
  .NavigationItem_NavigationItem__1c4md a:hover,
  .NavigationItem_NavigationItem__1c4md a:active,
  .NavigationItem_NavigationItem__1c4md a.NavigationItem_active__QFPCu {
    box-shadow: none;
    font-weight: bold;
    color: #e6404d;
  }
}

.ProfileSetup_Wrapper__3sT9O {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.ProfileSetup_ProfileSetupContainer__1jDVt {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.ProfileSetup_ProfileSetup__3QuLn {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.ProfileSetup_ImgWrapper__rsw6o {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.ProfileSetup_ImgWrapper__rsw6o img {
  width: 100%;
}

.ProfileSetup_Title__3-125 {
  font-size: 1.5em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 10px;
}
.ProfileSetup_Title__3-125 span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}
.ProfileSetup_CircularProgress__2-Kmc {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ProfileSetup_QuestionContainer__2pzg8 {
  height: 15rem;
  margin-bottom: 3.5rem;
}

/* START =========== Buttons */
.ProfileSetup_ButtonsContainer__MGEb8 {
  position: absolute;
  left: 0;
  bottom: 2rem;
  right: 0;
  text-align: center;
}
.ProfileSetup_Button__2XOzc {
  display: inline-block;
  margin: 0 1rem;
}
.ProfileSetup_SubmitButton__1Y0N1 {
  line-height: 0;
  width: auto;
  padding: 0 12px;
  height: 48px;
  margin: 0 8px;
  font-size: 1.2rem;

  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;

  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.NextButton_NextButton__1IVvS {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: rgba(0, 78, 124, 0.178);
  border: none;
  border-radius: 50px;
}

.NextButton_NextButton__1IVvS:hover {
  background-color: #003c71;
  color: white;
}

.NextButton_NextButton__1IVvS:active {
  background-color: #003c71;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.NextButton_NextButton__1IVvS i {
  padding-left: 2px;
}

.BackButton_BackButton__1Whr- {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: rgba(0, 78, 124, 0.178);
  border: none;
  border-radius: 50px;
}

.BackButton_BackButton__1Whr-:hover {
  background-color: #003c71;
  color: white;
}

.BackButton_BackButton__1Whr-:active {
  background-color: #003c71;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.BackButton_BackButton__1Whr- i {
  padding-right: 2px;
}

.CircularProgressBar_Circle__1WwaZ {
  position: relative;
  width: 50px;
  height: 50px;
  border: 4px solid #e0004d;
  box-sizing: border-box;
  border-radius: 50%;
  /* animation-name: progress;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Circle__1WwaZ::after {
  content: "";
  position: absolute;
  top: -21px;
  left: -21px;
  width: 180px;
  height: 180px;
  border: 22px solid #fff;
  box-sizing: border-box;
  border-radius: 50%;
  -webkit-clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
          clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
  /* animation-name: progress_after;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Etape__2Ceyx {
  margin-top: 0.35em;
  font-weight: bold;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

/* SART ------------Basic Input--------------- */
.Input_InputGroup__26hw3 {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Input_Input__Odr9H {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Input_Input__Odr9H:focus {
  outline: none;
}
.Input_Phone__1U7F- {
  padding-left: 35px;
}
.Input_InputGroup__26hw3 i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
/* LABEL ======= */
.Input_InputLabel__3fysu {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Input_Input__Odr9H:focus ~ .Input_InputLabel__3fysu,
.Input_Input__Odr9H:valid ~ .Input_InputLabel__3fysu {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Input_Bar__1Qc2N {
  position: relative;
  display: block;
  width: 100%;
}
.Input_Bar__1Qc2N:before,
.Input_Bar__1Qc2N:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Input_Bar__1Qc2N:before {
  left: 50%;
}
.Input_Bar__1Qc2N:after {
  right: 50%;
}
/* active state */
.Input_Input__Odr9H:focus ~ .Input_Bar__1Qc2N:before,
.Input_Input__Odr9H:focus ~ .Input_Bar__1Qc2N:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Input_Highlight__2ePD2 {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Input_Input__Odr9H:focus ~ .Input_Highlight__2ePD2 {
  animation: Input_inputHighlighter__ivO0l 0.3s ease;
  -webkit-animation: Input_inputHighlighter__ivO0l 0.3s ease;
  -moz-animation: Input_inputHighlighter__ivO0l 0.3s ease;
}
/* Animations */
@keyframes Input_inputHighlighter__ivO0l {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Input_inputHighlighter__ivO0l {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Input_Indicatif__3fgle {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Input_Input__Odr9H:focus ~ .Input_Indicatif__3fgle,
.Input_Input__Odr9H:valid ~ .Input_Indicatif__3fgle {
  opacity: 1;
}

/* START -----------Radio Button----------- */

.Input_RadioFormElement__oOpZN {
  /* margin-top: 10px; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.Input_RadioFormElement__oOpZN::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.Input_RadioDivElement__53jAQ {
  flex: 0 0 auto;
  margin: 20px 0;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  position: relative;
}
.Input_RadioDivElement__53jAQ:first-child {
  margin-left: 10vw;
}

.Input_RadioDivElement__53jAQ input {
  opacity: 0;
}

.Input_RadioDivElement__53jAQ label {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  /* OmbreOUT */
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;
  cursor: pointer;
}
.Input_RadioDivElement__53jAQ span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Input_RadioDivElement__53jAQ input[type="radio"]:checked + label {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
  color: #e6404d;
  font-weight: bold;
}

/* END -----------Radio Button----------- */

/* Small tablets */

.QuestionTwo_QuestionTwo__1mYIk {
  margin: 1.3em 0;
  text-align: center;
}
.QuestionTwo_QuestionTwo__1mYIk p {
  margin: 20px;
  font-size: 1.1rem;
}

.Radio_FormTitle__LrGxb {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  text-align: center;
}

.Radio_Form__bp-pt {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Radio_FormElement__14LBA {
  margin: 5px 5px;
  width: 3.2em;
  height: 3.2em;
  position: relative;
}

.Radio_FormElement__14LBA input[type="radio"] {
  display: none;
}

.Radio_FormElement__14LBA label {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 15%;
  border: none;
  background-color: rgba(0, 78, 124, 0.178);
  color: #777;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Radio_FormElement__14LBA input[type="radio"]:checked + label {
  background-color: #003c71;
  color: white;
}

/* .Travaux {
  overflow-x: hidden;
} */

.Travaux_Main__1BJPE {
  padding: 0 1em;
}
.Travaux_CardListTitle__3_pW6 {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.Travaux_CardListTitle__3_pW6 span {
  font-size: 1.5rem;
  font-weight: bold;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Travaux_Travaux__1yqYh {
    margin: 0 2em;
    margin-top: 1em;
  }
  .Travaux_MainCards__1UrWw {
    display: flex;
    position: relative;
    min-height: 40em;
  }
  .Travaux_NoCard__2DbwI {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Travaux_Travaux__1yqYh {
    margin: 0 10em;
  }
}

.IntroModal_IntroModal__O3fqk {
  height: 50vh;
  overflow-y: scroll;
  margin-bottom: 3em;
}

.IntroModal_IntroTitle__1v8iQ {
  /* margin-right: 1em; */
}

.IntroModal_IntroDescription__PkQh0 {
  margin-right: 1em;
}

.IntroModal_IntroButton__1SZHo {
  margin-top: 1.5em;
  text-align: center;
  margin-right: 1em;
  margin-bottom: 1em;
  position: fixed;
  bottom: 0.2em;
  width: 90%;
}

.IntroModal_Personnalisable__2DfVZ {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0)), color-stop(40%, #bbd5ff));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #bbd5ff 40%);
}

.IntroModal_Informative__2bJpz {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0)), color-stop(40%, #fcd79d));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #fcd79d 40%);
}

.Toolbar_Toolbar__3D7-H {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 3em;
}

.Toolbar_Logo__3ARuS h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #5b5855;
  font-weight: bold;
  font-family: "Arial Black", Gadget, sans-serif;
  cursor: pointer;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Toolbar_Toolbar__3D7-H {
    padding: 0 1em;
  }
  .Toolbar_Logo__3ARuS h3 {
    font-size: 1.8rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Toolbar__3D7-H {
    padding: 2em 1em;
  }

  .Toolbar_Logo__3ARuS h3 {
    font-size: 2rem;
  }
}

.Filter_Filter__o-x2W {
  margin-bottom: 0.5em;
}
.Filter_Filter__o-x2W p {
  font-size: 0.9rem;
}
.Filter_Filter__o-x2W span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Filter_Categories__2SFpr form {
  display: flex;
  margin: 0.4rem 0;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.Filter_Categories__2SFpr form::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.Filter_Personnalisable__3HBSJ {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Personnalisable__3HBSJ label {
  background: #f1f3f5;
  color: #828489;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Personnalisable__3HBSJ input[type="checkbox"] {
  display: none;
}

.Filter_Personnalisable__3HBSJ input[type="checkbox"]:checked + label {
  background: #e0ecff;
  color: #263238;
}

/* =========================== */
.Filter_Informative__Ygq37 {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Informative__Ygq37 label {
  background: #f1f3f5;
  color: #828489;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Informative__Ygq37 input[type="checkbox"] {
  display: none;
}

.Filter_Informative__Ygq37 input[type="checkbox"]:checked + label {
  background: #fff3e0;
  color: #263238;
}

.CardList_CardList__2RCn_ {
  margin-bottom: 2em;
}

.CardList_List__3ZtN8 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .CardList_List__3ZtN8 {
    align-items: flex-start;
    width: 21em;
  }
}

.Card_Card__ejNKx {
  /* background-color: #e0ecff; */
  display: flex;
  border-radius: 14px;
  position: relative;
  padding: 0.3em 0;
  max-width: 22em;
  margin-bottom: 1em;
  cursor: pointer;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
}
.Card_SelectedCard__lsFnE {
  border: 2px solid #e57373;
}

.Card_Thumbnail__UxIdg {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.Card_Thumbnail__UxIdg img {
  border-radius: 14px;
  width: 100%;
}

.Card_Main__2f2VJ {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Card_Titre__3CFwl {
  display: flex;
  flex-direction: column;
}

.Card_Description__34ZaK {
  margin-right: 4em;
  font-size: 0.6rem;
}

.Card_Icon__v-8fX {
  position: absolute;
  bottom: 0.3em;
  right: 1em;
}

.Card_Tags__pjUuw {
  font-size: 0.55rem;
  color: white;
}
.Card_Tags__pjUuw span {
  background-color: #757575;
  padding: 0.2em 0.4em;
  border-radius: 24px;
  text-transform: capitalize;
}
.Card_Tags__pjUuw :last-child {
  margin-left: 0.4em;
}

.CardModal_CardModal__1c6jV {
  position: fixed;
  z-index: 500;
  background: white;
  overflow-y: scroll;
  /* border-radius: 9px; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .CardModal_CardModal__1c6jV {
    position: static;
    overflow-y: hidden;
    background-color: #f3f2f0;
    width: auto;
    height: auto;
    padding: 2em;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1400px) {
  .CardModal_CardModal__1c6jV {
    padding: 0;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
  }
}

.concertableLayoutOne_CardHeader__k7Cp7 {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.concertableLayoutOne_CardTitle__2-s3k h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.concertableLayoutOne_CardTitle__2-s3k h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.concertableLayoutOne_Score__RbxBa {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.concertableLayoutOne_Score__RbxBa p {
  font-size: 0.9rem;
  color: #e0004d;
}
.concertableLayoutOne_Score__RbxBa span {
  font-weight: bold;
}

.concertableLayoutOne_ImagePreview__3Q1xk {
  margin: 1.5em auto;
  width: 80%;
  max-width: 25em;
}
.concertableLayoutOne_ImagePreview__3Q1xk img {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}

.concertableLayoutOne_CardContent__F-b7T {
  margin: 0 1.5em;
}

.concertableLayoutOne_Emoji__3B2YM {
  font-size: 1.8rem;
}
.concertableLayoutOne_DescriptionTitle__Sr8Cc p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.concertableLayoutOne_DescriptionContent__3Qe-y {
  margin-top: 0.5em;
  color: #898786;
  font-size: 0.8rem;
}

.concertableLayoutOne_QuestionsHeader__1BnSb {
  color: #e0004d;
  margin-top: 0.7em;
}

.concertableLayoutOne_QuestionTitle__3l2O1 {
  margin-top: 0.5em;
  font-size: 0.85em;
  display: flex;
}
.concertableLayoutOne_QuestionTitle__3l2O1 p {
  color: #2a2f39;
  margin-left: 0.4em;
}

/* ============================== radio form */
.concertableLayoutOne_RadioForm__3C2UL {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.concertableLayoutOne_RadioElement__23_ll {
  margin: 1em 0.8em;
}

.concertableLayoutOne_RadioElement__23_ll label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.concertableLayoutOne_RadioElement__23_ll p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.concertableLayoutOne_RadioElement__23_ll img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 3.5em;
  border-radius: 50%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14);
}

.concertableLayoutOne_RadioElement__23_ll input {
  display: none;
}

.concertableLayoutOne_RadioElement__23_ll input[type="radio"]:checked + label img {
  border: 7px solid #e0004d;
}
.concertableLayoutOne_RadioElement__23_ll input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* ============ END RADIO FORM */
.concertableLayoutOne_Button__1lU1o {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.concertableLayoutOne_CommentSection__gaQQA {
  margin-top: 1em;
  text-align: center;
}

.concertableLayoutOne_MUIForm__3HdfE {
  margin-left: 2em;
}

.concertableLayoutOne_NCSection__BJ6ZM {
  margin-top: 1.1em;
}
.concertableLayoutOne_MapImage__6Ha5W {
  margin-top: 0.85em;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .concertableLayoutOne_Card__1sLjY {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .concertableLayoutOne_LeftSection__3dxe9 {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .concertableLayoutOne_RightSection__2F5Jg {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .concertableLayoutOne_RightFooter__HLfdk {
    margin-top: 2em;
  }
}

.NonConcertableLayoutOne_CardHeader__3C_J6 {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.NonConcertableLayoutOne_CardTitle__o11fd h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.NonConcertableLayoutOne_CardTitle__o11fd h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.NonConcertableLayoutOne_Score__1BKXn {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.NonConcertableLayoutOne_Score__1BKXn p {
  font-size: 0.9rem;
  color: #e0004d;
}
.NonConcertableLayoutOne_Score__1BKXn span {
  font-weight: bold;
}

.NonConcertableLayoutOne_YoutubePlayer__c_xPp {
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.NonConcertableLayoutOne_CardContent__3--UG {
  margin: 0 1.5em;
}

.NonConcertableLayoutOne_Emoji__1xHse {
  font-size: 1.8rem;
}
.NonConcertableLayoutOne_DescriptionTitle__1vDHZ p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.NonConcertableLayoutOne_DescriptionContent__2ogZH {
  margin-top: 0.5em;
  color: #2d2926;
  font-size: 0.85rem;
}

.NonConcertableLayoutOne_NCSection__349fx {
  margin-top: 1.1em;
}

.NonConcertableLayoutOne_NCItem__1b-M2 {
  margin-left: 1em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}
.NonConcertableLayoutOne_NCItem__1b-M2 .NonConcertableLayoutOne_Emoji__1xHse {
  font-size: 1.7em;
}
.NonConcertableLayoutOne_NCItem__1b-M2 p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.NonConcertableLayoutOne_MapImage__2-QRb {
  margin-top: 0.85em;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
}

/* ============ END RADIO FORM */
.NonConcertableLayoutOne_Button__2HbSl {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.NonConcertableLayoutOne_CommentSection__2PFwg {
  margin-top: 1em;
  text-align: center;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .NonConcertableLayoutOne_Card__3vIbY {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .NonConcertableLayoutOne_LeftSection__2v-Pb {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .NonConcertableLayoutOne_RightSection__3bWn7 {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .NonConcertableLayoutOne_MUIForm__1Rx0G {
    margin-bottom: 8em;
  }
  .NonConcertableLayoutOne_RightFooter__2y556 {
    position: absolute;
    bottom: 0;
    right: 1em;
    width: 90%;
  }
}

.ProfilePage_HeaderImageWrapper__2FITv {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.ProfilePage_HeaderImageWrapper__2FITv img {
  width: 80%;
}

.ProfilePage_ProfilePage__3RGWc h1 {
  margin: 1rem 2rem;
}
.ProfilePage_ProfilePage__3RGWc h3 {
  margin: 1rem 0 1rem 2rem;
}
.ProfilePage_SubmitButton__122xH {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.OvalButton_OvalButton__21x3U {
  display: flex;
  margin: 0 auto;
  padding: 20px 40px;
  border: 0;
  outline: 0;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 41px;
}

.OvalButton_OvalButton__21x3U:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.Resultats_Main__3vePT {
  padding: 0 1em;
}
.Resultats_CardListTitle__7W-HL {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.Resultats_CardListTitle__7W-HL span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Resultats_IllustrationsSection__l6rCx {
  padding: 0 1em;
  margin-top: 0.5em;
}
.Resultats_StatsIllustration__2RGJw {
  margin-top: 2em;
}
.Resultats_StatsContent__5_Y0c {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Resultats_StatsContent__5_Y0c h3 {
  font-size: 1.4rem;
  font-weight: normal;
}
.Resultats_StatsContent__5_Y0c p {
  font-size: 2rem;
  font-weight: bold;
}
.Resultats_Illustration__1zpaC {
  margin: 1em auto;
  width: 80%;
  max-width: 25em;
  display: flex;
  justify-content: center;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Resultats_Travaux__ZeKJy {
    margin: 0 2em;
    margin-top: 1em;
  }
  .Resultats_MainCards__RyTj8 {
    display: flex;
    position: relative;
    min-height: 40em;
  }
  .Resultats_NoCard__1236N {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Resultats_Travaux__ZeKJy {
    margin: 0 10em;
  }
  /* .IllustrationsContent {
    display: flex;
  } */
}

.ResultsConcertableLayout_CardHeader__OKMYF {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.ResultsConcertableLayout_CardTitle__2s7-c h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.ResultsConcertableLayout_CardTitle__2s7-c h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.ResultsConcertableLayout_Score__1rJE1 {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.ResultsConcertableLayout_Score__1rJE1 p {
  font-size: 0.9rem;
  color: #e0004d;
}
.ResultsConcertableLayout_Score__1rJE1 span {
  font-weight: bold;
}

.ResultsConcertableLayout_ImagePreview__19ydg {
  margin: 1.5em auto;
  width: 80%;
  max-width: 25em;
}
.ResultsConcertableLayout_ImagePreview__19ydg img {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}

.ResultsConcertableLayout_CardContent__1ui2a {
  margin: 0 1.5em;
}

.ResultsConcertableLayout_Emoji__16BFx {
  font-size: 1.8rem;
}
.ResultsConcertableLayout_DescriptionTitle__1COOV p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.ResultsConcertableLayout_DescriptionContent__duCex {
  margin-top: 0.5em;
  color: #898786;
  font-size: 0.8rem;
}

.ResultsConcertableLayout_QuestionsHeader__1ZvB8 {
  color: #e0004d;
  margin-top: 0.7em;
}

.ResultsConcertableLayout_QuestionTitle__3CEo9 {
  margin-top: 0.5em;
  font-size: 0.85em;
  display: flex;
}
.ResultsConcertableLayout_QuestionTitle__3CEo9 p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ResultsConcertableLayout_QuestionEnsemble__dJEiJ {
  display: flex;
  margin: 1em 0;
}
.ResultsConcertableLayout_QuestionPercentage__3sodS {
  /* margin-right: 1em; */
  width: 6em;
}
.ResultsConcertableLayout_Winner__1LtYD {
  background-color: #e0004e71;
  padding: 0.3em;
  border-radius: 10px;
  color: #f3f2f0;
  margin-left: 0.5em;
}
.ResultsConcertableLayout_QuestionSentence__2KdY4 {
  margin-left: 0.5em;
}
/* ============================== radio form */
.ResultsConcertableLayout_RadioForm__1E2Iv {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.ResultsConcertableLayout_RadioElement__1u4Ry {
  margin: 1em 0.8em;
}

.ResultsConcertableLayout_RadioElement__1u4Ry label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.ResultsConcertableLayout_RadioElement__1u4Ry p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.ResultsConcertableLayout_RadioElement__1u4Ry img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 3.5em;
  border-radius: 50%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14);
}

.ResultsConcertableLayout_RadioElement__1u4Ry input {
  display: none;
}

.ResultsConcertableLayout_RadioElement__1u4Ry input[type="radio"]:checked + label img {
  border: 7px solid #e0004d;
}
.ResultsConcertableLayout_RadioElement__1u4Ry input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* ============ END RADIO FORM */
.ResultsConcertableLayout_Button__2hcRp {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.ResultsConcertableLayout_CommentSection__3_DqH {
  margin-top: 1em;
}
.ResultsConcertableLayout_Comment__1QJEc {
  display: flex;
  margin-top: 0.8em;
}
.ResultsConcertableLayout_Comment__1QJEc p {
  margin-left: 1em;
}

.ResultsConcertableLayout_MUIForm__UbFa_ {
  margin-left: 2em;
}

.ResultsConcertableLayout_NCSection__3JEmC {
  margin-top: 1.1em;
}
.ResultsConcertableLayout_MapImage__2ZLo5 {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.ResultsConcertableLayout_Notices__2MMY4 {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .ResultsConcertableLayout_Card__1nyMi {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .ResultsConcertableLayout_LeftSection__1jQ6n {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .ResultsConcertableLayout_RightSection__3syj3 {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .ResultsConcertableLayout_RightFooter__38pSm {
    margin-top: 2em;
  }
}

.ResultsNonConcertableLayout_CardHeader__Pe7ET {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.ResultsNonConcertableLayout_CardTitle__6fmCQ h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.ResultsNonConcertableLayout_CardTitle__6fmCQ h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.ResultsNonConcertableLayout_Score__1IlDi {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.ResultsNonConcertableLayout_Score__1IlDi p {
  font-size: 0.9rem;
  color: #e0004d;
}
.ResultsNonConcertableLayout_Score__1IlDi span {
  font-weight: bold;
}

.ResultsNonConcertableLayout_YoutubePlayer__1NnJu {
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.ResultsNonConcertableLayout_CardContent__1NLyd {
  margin: 0 1.5em;
}

.ResultsNonConcertableLayout_Emoji__2ffA- {
  font-size: 1.8rem;
}
.ResultsNonConcertableLayout_DescriptionTitle__16WCc p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.ResultsNonConcertableLayout_DescriptionContent__2bdps {
  margin-top: 0.5em;
  color: #2d2926;
  font-size: 0.85rem;
}

.ResultsNonConcertableLayout_NCSection__1W2ub {
  margin-top: 1.1em;
}

.ResultsNonConcertableLayout_NCItem__398NW {
  margin-left: 1em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}
.ResultsNonConcertableLayout_NCItem__398NW .ResultsNonConcertableLayout_Emoji__2ffA- {
  font-size: 1.7em;
}
.ResultsNonConcertableLayout_NCItem__398NW p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.ResultsNonConcertableLayout_MapImage__22kEr {
  margin-top: 0.85em;
}
.ResultsNonConcertableLayout_MapImage__22kEr img {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
}

/* ============ END RADIO FORM */
.ResultsNonConcertableLayout_Button__sqiG1 {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.ResultsNonConcertableLayout_CommentSection__1SWFP {
  margin-top: 1em;
}
.ResultsNonConcertableLayout_Comment__3QFCi {
  display: flex;
  margin-top: 0.8em;
}
.ResultsNonConcertableLayout_Comment__3QFCi p {
  margin-left: 1em;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .ResultsNonConcertableLayout_Card__38f4A {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .ResultsNonConcertableLayout_LeftSection__uTCSj {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .ResultsNonConcertableLayout_RightSection__24p56 {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .ResultsNonConcertableLayout_MUIForm__moPkR {
    margin-bottom: 8em;
  }
  .ResultsNonConcertableLayout_RightFooter__acFNy {
    position: absolute;
    bottom: 0;
    right: 1em;
    width: 90%;
  }
}

.PageOne_SectionTitle__3CwWa {
  text-align: center;
}
.PageOne_SectionTitle__3CwWa .PageOne_Emoji__Uohtk {
  font-size: 2.8em;
}
.PageOne_SectionTitle__3CwWa h3 {
  font-size: 1.5em;
}
.PageOne_SectionSubtitle__rrxQl {
  font-weight: bold;
  font-size: 0.8em;
  margin: 0.8em 2em;
}

.PageOne_EmojiText__x26WS {
  display: flex;
  margin: 0 2em;
}
.PageOne_EmojiText__x26WS .PageOne_Emoji__Uohtk {
  font-size: 1.2em;
}

.PageOne_EmojiText__x26WS p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.PageOne_ParticiperButton__2MD2u {
  text-align: center;
  margin: 1.5em 0;
}

.PageOne_Illustration__2QfeI {
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2em auto;
}

/* Caroussel */
.PageOne_CarouselSection__sfUPE {
  margin: 30px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.PageOne_CarouselSection__sfUPE::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.PageOne_CarouselItem__2Jnbr {
  flex: 0 0 auto;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 30px;
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.PageOne_CarouselItem__2Jnbr:first-child {
  margin-left: 40px;
}
.PageOne_CourselItem__1wa-L:last-child {
  margin-right: 20px;
}

.PageOne_GrandesEtapes__2TPtd {
  display: flex;
  justify-content: center;
}
.PageOne_GrandesEtapes__2TPtd img {
  width: 80%;
  max-width: 33em;
  margin-bottom: -3em;
}
.PageOne_HeroImage__1muOu {
  width: 100%;
  margin-bottom: 1.5em;
  z-index: -1;
}
.PageOne_HeroImage__1muOu img {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .PageOne_HeroImage__1muOu {
    width: 40em;
    margin: 0 auto;
    margin-bottom: 3em;
  }
  .PageOne_HeroImage__1muOu img {
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.14);
  }
  .PageOne_Section__SKnjr {
    margin: 0 5.5em;
  }
  .PageOne_SectionTitle__3CwWa {
    display: flex;
    align-items: flex-end;
  }
  .PageOne_SectionSubtitle__rrxQl {
    font-weight: bold;
    font-size: 0.9em;
    margin: 0.8em 0;
  }
  .PageOne_ContactForm__2kwUq {
    margin: 0 auto;
    max-width: 40em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .PageOne_HeroImage__1muOu {
    width: 50em;
    margin: 0 auto;
    margin-bottom: 4em;
  }
  .PageOne_Section__SKnjr {
    margin: 0 20em;
  }
  .PageOne_Illustration__2QfeI {
    width: 25em;
    height: 25em;
  }
}

