.CardHeader {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.CardTitle h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.CardTitle h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.Score {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.Score p {
  font-size: 0.9rem;
  color: #e0004d;
}
.Score span {
  font-weight: bold;
}

.YoutubePlayer {
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.CardContent {
  margin: 0 1.5em;
}

.Emoji {
  font-size: 1.8rem;
}
.DescriptionTitle p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.DescriptionContent {
  margin-top: 0.5em;
  color: #2d2926;
  font-size: 0.85rem;
}

.NCSection {
  margin-top: 1.1em;
}

.NCItem {
  margin-left: 1em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}
.NCItem .Emoji {
  font-size: 1.7em;
}
.NCItem p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.MapImage {
  margin-top: 0.85em;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
}

/* ============ END RADIO FORM */
.Button {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.CommentSection {
  margin-top: 1em;
  text-align: center;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .Card {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .LeftSection {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .RightSection {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .MUIForm {
    margin-bottom: 8em;
  }
  .RightFooter {
    position: absolute;
    bottom: 0;
    right: 1em;
    width: 90%;
  }
}
