.CardHeader {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.CardTitle h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.CardTitle h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.Score {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.Score p {
  font-size: 0.9rem;
  color: #e0004d;
}
.Score span {
  font-weight: bold;
}

.ImagePreview {
  margin: 1.5em auto;
  width: 80%;
  max-width: 25em;
}
.ImagePreview img {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}

.CardContent {
  margin: 0 1.5em;
}

.Emoji {
  font-size: 1.8rem;
}
.DescriptionTitle p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.DescriptionContent {
  margin-top: 0.5em;
  color: #898786;
  font-size: 0.8rem;
}

.QuestionsHeader {
  color: #e0004d;
  margin-top: 0.7em;
}

.QuestionTitle {
  margin-top: 0.5em;
  font-size: 0.85em;
  display: flex;
}
.QuestionTitle p {
  color: #2a2f39;
  margin-left: 0.4em;
}

/* ============================== radio form */
.RadioForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.RadioElement {
  margin: 1em 0.8em;
}

.RadioElement label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.RadioElement p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.RadioElement img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 3.5em;
  border-radius: 50%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14);
}

.RadioElement input {
  display: none;
}

.RadioElement input[type="radio"]:checked + label img {
  border: 7px solid #e0004d;
}
.RadioElement input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* ============ END RADIO FORM */
.Button {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.CommentSection {
  margin-top: 1em;
  text-align: center;
}

.MUIForm {
  margin-left: 2em;
}

.NCSection {
  margin-top: 1.1em;
}
.MapImage {
  margin-top: 0.85em;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .Card {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .LeftSection {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .RightSection {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .RightFooter {
    margin-top: 2em;
  }
}
